import React, { useEffect, useRef, useState } from "react"
import { ITransferHistory, removeTransfer_API } from "../../../api/transfers"
import { getEventPath } from "../../../utils/getEventPath"
import { useToastMessage } from "../../../Context/ToastContext/useToastContext"

/**A custom use hook for the cancel transfer modal */
export const useCancelTransferModal = (
        selectedTransfer: ITransferHistory,
        transferHistory: Array<ITransferHistory>, 
        setTransferHistory: React.Dispatch<React.SetStateAction<Array<ITransferHistory>>>,
        setOpenModal: React.Dispatch<React.SetStateAction<boolean>>,
        setTransferErrored: React.Dispatch<React.SetStateAction<boolean>> | undefined,
        transferErrored: boolean | undefined,
        setOpenTransferDetails?: React.Dispatch<React.SetStateAction<boolean>>,
    ) => {
    
    // const [transferErrored, setTransferErrored] = useState<boolean>(false)
    const {type, message, resetToast, setToastMessage} = useToastMessage()
    const [cancelFailed, setCancelFailed] = useState<boolean>(false)
    const newTransferArrayRef = useRef<Array<ITransferHistory>>([])

    /**Checks for click outside of menu box, if ouside then the menu box is closed */
    useEffect(() => {
        const handleClickOutside = (event: any) => {
            if(transferErrored !== undefined && !transferErrored){
                const path = getEventPath(event)
                if(Array.from(path).findIndex((e: any) => e.classList && Array.from(e.classList).includes("cancel-modal-details-wrap")) === -1){
                    document.body.style.overflow = "auto";
                    setOpenModal(false)
                }
            }
        }

        document.addEventListener("click", handleClickOutside, true)
        return () => document.removeEventListener('click', handleClickOutside, true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])    

    /**handle the cancel transfer onClick event */
    const handleTransferCancel = async () => {
        const index = transferHistory.findIndex((transfer: ITransferHistory) => transfer.id === selectedTransfer.id)
        // newTransferArrayRef.current = JSON.parse(JSON.stringify(transferHistory))
        newTransferArrayRef.current = transferHistory
        try{
            await removeTransfer_API(selectedTransfer.id)
            newTransferArrayRef.current.splice(index, 1)
            setTransferHistory(newTransferArrayRef.current)
            setOpenModal(false)
            //if(setOpenTransferDetails){
                // setOpenTransferDetails(false)
            //}
            setToastMessage("SUCCESS", "Transfer canceled")    
        }
        catch(error: any){
            let errorReturn: any = error.response ? JSON.parse(JSON.stringify(error.response)) : ""
            if(errorReturn !== "" && errorReturn.data.errorMsg && errorReturn.data.errorMsg[0] === "[Validation failed for cancel order, Caused by: Order is completed and cannot be canceled]"){
                if(setTransferErrored){setTransferErrored(true)}
                newTransferArrayRef.current.splice(index, 1)
                setCancelFailed(true)
                if(newTransferArrayRef.current.length > 0){
                    setTransferHistory(newTransferArrayRef.current)
                }
            }
            else{
                console.log("UNABLE TO CANCEL THE TRANSFER.")
                setToastMessage("ERROR", "Unable to save changes. Please try again later.")
                resetToast()
            }
        }
        Promise.resolve(true)
    }

    /**Locks the scroll bar when the modal renders, then unlocks the scroll bar on unmount */
    useEffect(() => {
        document.body.style.overflow = "hidden"
        return () => {document.body.style.overflow = "auto"}
    }, [])

    /**Handles the 'Close' button on click event that occurs when a call to cancel a scheduled transfer fails */
    const handleCloseButtonModalClick = () => {
        setTransferHistory([])
        // setOpenModal(false)
    }

    return { handleTransferCancel, type, message, cancelFailed, handleCloseButtonModalClick, newTransferArrayRef }
}